import request from '../request'
import axios from 'axios'
import qs from 'qs';

// export const login = (params) => {
//     return axios.post(`/api/blade-party-admin/dict-biz/dictionary${params.param}`, params)
//         .then(res => res)
//         .catch(err => { console.log(err) });
// };
const auth = localStorage.getItem('Blade-Auth-Token');


// 钉钉登录接口
export const dingdingLogin = (params) => {
    return request({
        url: `/blade-party-admin/min/dingtalk/auth/login`,
        params: params,
        method: 'GET',
        headers: {
            'Authorization': 'Basic c3dvcmQ6c3dvcmRfc2VjcmV0'
        }
    })
};

export const dingdingFakeLogin = (params) => {
    return request({
        url: `/blade-party-admin/min/dingtalk/auth/login`,
        params: params,
        method: 'GET',
        headers: {
            'Captcha-Key': 'eb4f770b248e6531ec892f8af300eecf',
            'Captcha-Code': 'qazwsx',
            'Authorization': 'Basic c3dvcmQ6c3dvcmRfc2VjcmV0'

        }
    })
};
// 获取党建风采
export const getDangjianElegant = (params) => {
    return request({
        url: `/blade-party-admin/min/home/list/party-style`,
        data: params,
        method: 'GET',
        headers: {
            'Blade-Auth': auth
        }
    })
};

// 获取首页轮播图
export const getBannersList = (params) => {
    return request({
        url: `/blade-party-admin/min/home/list/banners`,
        data: params,
        method: 'GET',
        headers: {
            'Blade-Auth': auth
        }
    })
};
// 获取党员信息
export const getUserInfo = (params) => {
    return request({
        url: `/blade-party-admin/min/person/info`,
        data: params,
        method: 'POST',
        headers: {
            'Blade-Auth': auth
        }
    })
};
// 获取积分
export const getScoreintegralinfos = (params) => {
    return request({
        url: `/blade-party-admin/dingtalk/scoreintegralinfos/info`,
        data: params,
        method: 'GET',
        headers: {
            'Blade-Auth': auth
        }
    })
};


// 文件上传
export const uploadFile = (params) => {
    // return request({
    //     url: `/blade-party-admin/file/put-file`,
    //     data: qs.stringify(params),
    //     method: 'POST',
    //     headers: {
    //         'Blade-Auth': auth,
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     }
    // })
    return axios.post(`/blade-party-admin/file/put-file`, params, { headers: { 'Blade-Auth': auth, 'Content-Type': 'multipart/form-data', 'User-Type': 'DINGTALK' } });
};
