<template >
  <div class="containter">
    <div style="height:2rem;width:100%;"></div>
    <div class="loadingArea">
      <van-loading type="spinner" color="rgb(88,188,167)" />
    </div>
    <div style="height:2rem;width:100%;"></div>
    <van-progress
      style="margin:0 0.5rem;"
      color="rgb(88,188,167)"
      pivot-color="rgb(88,188,167)"
      :percentage="percent"
      stroke-width="12"
    />
    <van-overlay :show="show">
      <div class="wrapper" @click.stop="show=false">
        <div class="block">
          <p class="call">您的钉钉信息不全 登录失败</p>
          <p class="is">
            <span @click.stop="toGoBack" style="margin-right:0.5rem">返回</span>
            <!-- <span @click.stop="show=false">否</span> -->
          </p>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="timeoutShow">
      <div class="wrapper" @click.stop="timeoutShow=false">
        <div class="block">
          <p class="call">网络问题，是否退出？</p>
          <p class="is">
            <span @click.stop="toGoBack" style="margin-right:0.5rem">退出</span>
            <!-- <span @click.stop="show=false">否</span> -->
          </p>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Notify } from 'vant';
import * as dd from 'dingtalk-jsapi';
import { dingdingLogin, dingdingFakeLogin } from '../../api/mainPage';
import VConsole from 'vconsole/dist/vconsole.min.js';
export default {
  name: 'login',

  data() {
    return {
      percent: 0,
      timer: '',
      show: false,
      timeoutTimer: {},
      timeoutShow: false
    };
  },
  watch: {},
  mounted() {
    const this_ = this;
    // 设置进度条
    this_.timer = setInterval(function() {
      const percent = this_.percent;
      if (percent < 90) {
        this_.percent += 10;
      }
    }, 200);
  },
  created() {
    //判断上一个页面来自哪里

    this.dingdingLogin();
    // this.fakeLogin();
    // let vConsole = new VConsole();
    // 设置一个计时器，如果超时就退出
    this.timeoutTimer = setTimeout(function() {
      this.timeoutShow = true;
    }, 8000);
  },
  // 如果从首页返回就退出
  beforeRouteEnter(to, from, next) {
    console.log(from);
    if (from.path === '/mainPage') {
      dd.biz.navigation.close({
        onSuccess: function(result) {
          /*result结构
        {}
        */
        },
        onFail: function(err) {}
      });
    }
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      console.log(vm); //当前组件的实例
    });
  },

  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
    const this_ = this;
    clearInterval(this_.timer);
    clearTimeout(this_.timeoutTimer);
  },
  methods: {
    dingdingLogin() {
      const this_ = this;
      if (dd.env.platform == 'notInDingTalk') {
        console.log('不是钉钉环境');
      } else {
        dd.ready(function() {
          // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
          dd.runtime.permission.requestAuthCode({
            // corpId: 'dingb46c585ac7bcae8c35c2f4657eb6378f', // 惊奇网络
            // corpId: 'dingf9b677ca8efb19034ac5d6980864d335', // 梅城
            corpId: 'ding6de685f098084e97a1320dcb25e91351',
            onSuccess: function(result) {
              console.log('鉴权成功' + console.log(result.code));
              dingdingLogin({
                authCode: result.code,
                isPhone: false
              })
                .then(res => {
                  console.log(res);
                  const result = res;
                  this_.percent = 100;
                  localStorage.setItem(
                    'Blade-Auth-Token',
                    `${result.token_type} ${result.access_token}`
                  );
                  localStorage.setItem('roleName', result.role_name);
                  localStorage.setItem('Avatar', result.avatar);
                  localStorage.setItem('userName', result.user_name);
                  localStorage.setItem('nickName', result.nick_name);
                  Notify({ type: 'success', message: '登录成功' });
                  this_.$router.push({
                    name: 'mainPage'
                  });
                })
                .catch(err => {
                  console.log(err);
                  this_.show = true;
                  Notify({ type: 'warning', message: '登录失败' });
                  return false;
                });
            },
            onFail: function(err) {
              console.log(err, 'ding6de685f098084e97a1320dcb25e91351');
            }
          });
        });
      }
    },
    fakeLogin() {
      const this_ = this;
      dingdingFakeLogin({
        authCode: '15858185925',
        tenantId: '938942',
        isPhone: true
      })
        .then(res => {
          console.log(res);
          const result = res;
          this_.percent = 100;
          localStorage.setItem(
            'Blade-Auth-Token',
            `${result.token_type} ${result.access_token}`
          );
          localStorage.setItem('roleName', result.role_name);
          localStorage.setItem('Avatar', result.avatar);
          localStorage.setItem('userName', result.user_name);
          localStorage.setItem('nickName', result.nick_name);
          Notify({ type: 'success', message: '登录成功' });
          console.log('跳转');
          this_.$router.push({
            name: 'mainPage'
          });
        })
        .catch(err => {
          console.log(err);
          this_.show = true;
          Notify({ type: 'warning', message: '登录失败' });
          return false;
        });
    },
    submitInfo() {
      localStorage.clear();
      const this_ = this;
      const uername = this_.uername;
      // const password = this_.password;
      const yanzhengma = this_.yanzhengma;
      if (!uername || !yanzhengma) {
        Notify({ type: 'warning', message: '请输入账号或验证码' });
        return;
      }

      let param = {};
      param.username = uername;
      param.verificationCode = yanzhengma;
      // param.password = password;
      this_.$api.loginTown({ param: param }).then(res => {
        if (res.data.success) {
          const result = res.data.result;
          localStorage.setItem('X-Access-Token', result.token);
          if (result.isEnterprise) {
            localStorage.setItem('enterprise_if', 1);
            // localStorage.setItem('enterprise_List', JSON.stringify(result.enterpriseInfos));
            Notify({ type: 'success', message: '登录成功' });
            this_.$router.push({
              name: 'resumptionWorkCompanyList'
            });
            // localStorage.setItem('enterprise_name', result.enterpriseName);
            // localStorage.setItem('enterprise_code', result.enterpriseCode);
          } else {
            localStorage.setItem('enterprise_if', 2);
            Notify({ type: 'success', message: '登录成功' });
            this_.$router.push({ name: 'resumptionWork' });
          }
        } else {
          // Notify({ type: 'warning', message: '账号或密码错误' });
          Notify({ type: 'warning', message: '账号或验证码错误' });
          return false;
        }
      });
    },
    toGoBack() {
      dd.biz.navigation.close({
        onSuccess: function(result) {
          /*result结构
        {}
        */
        },
        onFail: function(err) {}
      });
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/75;
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
  /* background-color: #f5f5f5; */
}
.headArea {
  width: 100%;
  height: 5rem;
  position: relative;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.headPic {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0rem;
}
.titleFont {
  margin-top: 3rem;
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  color: rgb(34, 57, 137);
}
.noticeFont {
  text-align: center;
  font-size: 0.4rem;
}
.inputArea {
  margin: 0.5rem 0.8rem;
  position: relative;
}
.inputLine {
  width: 8.4rem;
  height: 1rem;
  margin-left: 0.8rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  /* border-bottom: rgb(119, 119, 119) 0.0267rem solid; */
}
.iconArea {
  width: 1rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.yanzhengmaArea {
  position: absolute;
  top: 1.6rem;
  right: 0.4rem;
  width: 2rem;
  height: 0.6rem;
  font-size: 0.3rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  border: rgb(34, 57, 137) 0.0267rem solid;
}
.loadingArea {
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .block {
    width: 550 * $px;
    height: 250 * $px;
    background-color: #fff;
    border-radius: 8 * $px;
    padding: 30 * $px;
    box-sizing: border-box;
    h3 {
      font-size: 40 * $px;
      padding-left: 20 * $px;
    }
    p {
      font-size: 34 * $px;
      &.call {
        margin: 40 * $px;
        margin-left: 20 * $px;
        text-align: center;
      }
      &.is {
        margin: 10 * $px 0 0 280 * $px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>



